<template>
  <v-main class="subscription-container">
    <v-container class="pt-0">
      <v-row class="align-center justify-center">
        <v-col cols="12" sm="12" md="11" lg="10" xl="10">
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-card>
              <v-card-title style="text-align: left">
                <h3>Zahlungsdaten aktualisieren</h3>
              </v-card-title>
              <v-divider/>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col cols="12" sm="12" md="8" lg="5" xl="4" class="pt-8">
                    <v-text-field
                        label="Zertifikats- oder Mitgliedsnummer"
                        v-model="subscription.certificate"
                        :rules="rules"
                        validate-on-blur required
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider/>
              <v-card-actions>
                <v-btn color="primary" @click="submit()" class="insurance-button"
                       :loading="loading" :disabled="loading">
                  Fortfahren und Zahlungsdaten aktualisieren
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
        :timeout="snackbar.timeout"
        :bottom="snackbar.bottom"
        :color="snackbar.color"
        v-model="snackbar.value">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon style="margin: 0 !important;"
               @click="snackbar.value = false">
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-main>
</template>

<script>

import { mdiClose } from "@mdi/js";

export default {
  name: "Subscription",
  data () {
    return {
      icons: {
        close: mdiClose,
      },
      snackbar: {
        value: false,
        text: '',
        color: 'success',
        timeout: 10000,
        bottom: true,
      },
      loading: false,
      valid: true,
      subscription: {
        certificate: null
      },
      stripe: {
        init: undefined,
      }
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.stripe.init = Stripe('pk_live_XYBYmtLKllz5NR5BfoNVeSbR00gtSDQ0fa');
    // eslint-disable-next-line no-undef
    //this.stripe.init = Stripe('pk_test_O8UmGuspsJQmozJuSBpqcPzP003KCzCj33');
    if (typeof this.$route.query.success !== 'undefined') {
      if (this.$route.query.success === 'true') {
        this.snackbar.text = 'Zahlungsdatenänderung wurde erfolgreich durchgeführt!';
        this.snackbar.color = 'success';
        this.snackbar.timeout = 10000;
        this.snackbar.value = true;
      } else {
        this.snackbar.text = 'Zahlungsdatenänderung konnte nicht durchgeführt werden!';
        this.snackbar.color = 'error';
        this.snackbar.timeout = 10000;
        this.snackbar.value = true;
      }
      this.$router.replace({});
    }
    if (typeof this.$route.query.id !== 'undefined') {
      this.subscription.certificate = this.$route.query.id;
    }
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        this.snackbar.text = 'Nicht alle Pflichtfelder wurden ausgefüllt!';
        this.snackbar.color = 'error';
        this.snackbar.timeout = 10000;
        this.snackbar.value = true;
      } else {
        this.loading = true;
        const subscription = JSON.parse(JSON.stringify(this.subscription));
        if (subscription.certificate.indexOf('MEMBER-') !== -1) {
          this.$http.post('member/subscription', subscription).then((data) => {
            this.stripe.init.redirectToCheckout({
              sessionId: data.body.session.id
            }).then((data) => {
              this.loading = false;
              if (data.error) {
                this.snackbar.text = 'Zahlungsdatenänderung konnte nicht gestartet werden!';
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
              }
            }, () => {
              this.loading = false;
              this.snackbar.text = 'Zahlungsdatenänderung konnte nicht gestartet werden!';
              this.snackbar.color = 'error';
              this.snackbar.timeout = 10000;
              this.snackbar.value = true;
            });
          }, (data) => {
            this.loading = false;
            // eslint-disable-next-line no-console
            console.error(data);
            this.snackbar.text = 'Zahlungsänderung konnte nicht gestartet werden!';
            this.snackbar.color = 'error';
            this.snackbar.timeout = 10000;
            this.snackbar.value = true;
          });
        } else {
          subscription.certificate.replace(/[I]+/g, 'O');
          this.$http.post('insurance/subscription', subscription).then((data) => {
            this.stripe.init.redirectToCheckout({
              sessionId: data.body.session.id
            }).then((data) => {
              this.loading = false;
              if (data.error) {
                this.snackbar.text = 'Zahlungsdatenänderung konnte nicht gestartet werden!';
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
              }
            }, () => {
              this.loading = false;
              this.snackbar.text = 'Zahlungsdatenänderung konnte nicht gestartet werden!';
              this.snackbar.color = 'error';
              this.snackbar.timeout = 10000;
              this.snackbar.value = true;
            });
          }, (data) => {
            this.loading = false;
            // eslint-disable-next-line no-console
            console.error(data);
            this.snackbar.text = 'Zahlungsänderung konnte nicht gestartet werden!';
            this.snackbar.color = 'error';
            this.snackbar.timeout = 10000;
            this.snackbar.value = true;
          });
        }
      }
    }
  },
  computed: {
    rules() {
      return [val => (val || '').length > 0 || this.$t('required')];
    },
  }
}
</script>

<style scoped lang="scss">
.subscription-container {
  margin-top: 150px;
  margin-bottom: 100px;
  .v-card {
    border-radius: 8px;
    .v-btn {
      text-transform: none;
      border-radius: 10px;
    }
    .v-input {
      border-radius: 10px;
    }
  }
  ::v-deep.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0 !important;
  }
  ::v-deep.v-input .v-label {
    color: black;
  }
}
</style>
